import { NgModule, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig } from '@app/primeng-overrides/dynamicdialog';
import { DynamicDialogRef } from '@app/primeng-overrides/dynamicdialog';

import { EvenementService, Evenement } from '@app/evenement/evenement.service';
import { MilieuSelectorModule } from '@app/evenement/milieu-selector';

import { EventManagerService, IEventListener } from '@global/event-manager.service';

@Component({
	selector: 'milieu-dialog',
	templateUrl: './milieu-dialog.html'
})
export class MilieuDialogComponent implements OnInit, IEventListener {

	loading: boolean;
	evenement: Evenement;

	currentMilieu: string = 'Aucun';
	selected: string|null = null;

	constructor(
		private dialogRef: DynamicDialogRef,
		private eventManager: EventManagerService,
		private config: DynamicDialogConfig,
		private evenementService: EvenementService,
	) {
		this.evenement = config.data.evenement;
		// on reset pour forcer l'utilisateur à sélectionner un milieu pour la confirmation
		if (!this.evenement.eve_milieu_modifie_le) {
			if (this.evenement.eve_milieu) {
				this.currentMilieu = this.evenement.eve_milieu;
			}
		}
		else {
			this.selected = this.evenement.eve_milieu;
		}
	}

	ngOnInit(): void {
		this.eventManager.registerEvent('close-modals', this, (args: any) => {
			this.dialogRef.close();
		});
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('close-modals', this);
	}

	cancel() {
		this.dialogRef.close();
	}

	submit() {
		if (this.selected) {
			this.loading = true;
			if (!this.evenement.eve_milieu_modifie_le) {
				this.evenement['confirmation_milieu'] = true;
			}
			this.evenement.eve_milieu = this.selected;
			this.evenementService.putEvenement(this.evenement)
			.subscribe({
				next: (response: Evenement) => {
					this.dialogRef.close(response.evenement);
				}
			})
			.add(() => { this.loading = false; });
		}
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		MilieuSelectorModule,
	],
	exports: [MilieuDialogComponent],
	declarations: [MilieuDialogComponent]
})
export class MilieuDialogModule { }

