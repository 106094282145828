import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AnalyticsService } from '@global/analytics.service';
import {
	clone,
	convertDateFieldsToDate,
	convertDateFieldsToString,
	prepareQueryParams,
	prepareQueryParamsForDownload,
	simpleDateToString,
} from '@helpers/utils';

export class OptionEvenement {
	[key: string]: any;
	ope_id: number;
	eve_option_id: string = '';
	mag_id: number;
	ope_statut: string|null;
	ope_date_modification_statut: string|Date;
	ope_commentaire: string;
	ope_code_postal_client: string;
	ope_ville_client: string;
	ope_mt_facture_htple: number|null = null;
	ope_mt_achat_facture_ht: number|null = null;
	ope_date_livraison_reelle: string|Date;

	constructor(eve_option_id: string, mag_id: number) {
		this.eve_option_id = eve_option_id;
		this.mag_id = mag_id;
	}
};

export class Evenement {
	[key: string]: any;
	eve_id: number;
	mag_id: number;
	eve_type: string;
	eve_option_id: string;
	eve_date_evenement: string|Date;
	uti_vendeur: number;
	eve_no_client: number;
	eve_client: string;
	eve_milieu: string;
	eve_milieu_modifie_le: string|Date;
	eve_entree: boolean;
	eve_retours: boolean;
	eve_prise_rdv: boolean;
	eve_mt_vente_total_htple: number;
	eve_mt_achat_ht: number;
	eve_mt_budget_ttc: number;
	eve_devis: boolean;
	eve_commande: boolean;
	eve_b2b: boolean;
	eve_masque: boolean;
	eve_date_attente_debut: string|Date;
	eve_date_attente_fin: string|Date;
	eve_parent_attente: number;

	option_evenement: OptionEvenement|null;

	readonly: boolean;
	confirmation_milieu: boolean;

	aud_date_modification: string|Date;

	constructor(eve_date_evenement: Date = new Date()) {
		this.eve_date_evenement = eve_date_evenement;
	}
};

@Injectable({ providedIn: 'root' })
export class EvenementService {

	constructor(
		private analyticsService: AnalyticsService,
		private http: HttpClient,
	) {

	}

	prepareEvenementsFromServer(evenements: Evenement[]) {
		evenements.forEach((one: Evenement) => {
			one = this.prepareEvenementFromServer(one);
		})
	}

	prepareEvenementFromServer(evenement: Evenement) {
		convertDateFieldsToDate(evenement);
		if (evenement.eve_type == 'O' && !evenement.option_evenement && evenement.eve_option_id) {
			evenement.option_evenement = new OptionEvenement(evenement.eve_option_id, evenement.mag_id);
		}
		if (evenement.option_evenement) {
			convertDateFieldsToDate(evenement.option_evenement);
		}
		return evenement;
	}

	getList(params: any, mag_id: number) {
		let tmpParams = prepareQueryParams(params);
		this.analyticsService.trackEvent('get_evenements', tmpParams.params);
		return this.http.get<any>(`/magasins/${mag_id}/evenements`, tmpParams)
		.pipe(map((response: any) => {
			this.prepareEvenementsFromServer(response.evenements);
			return response;
		}));
	}

	get(mag_id: number, eve_id: number) {
		return this.http.get<any>(`/magasins/${mag_id}/evenements/${eve_id}`)
		.pipe(map((response: any) => {
			this.prepareEvenementFromServer(response);
			return response;
		}));
	}

	putEvenement(evenement: Evenement) {
		this.analyticsService.trackEvent('edit_evenement', {
			eve_id: evenement.eve_id,
			mag_id: evenement.mag_id
		});
		return this.http.put<any>(`/magasins/${evenement.mag_id}/evenements/${evenement.eve_id}`, evenement)
		.pipe(map((response: any) => {
			this.prepareEvenementFromServer(response.evenement);
			return response;
		}));
	}

	postEvenement(evenement: Evenement) {
		let tmp = clone(evenement);
		convertDateFieldsToString(tmp);
		this.analyticsService.trackEvent('creation_evenement', tmp);
		return this.http.post<any>(`/magasins/${evenement.mag_id}/evenements`, tmp);
	}

	deleteEvenement(evenement: Evenement) {
		this.analyticsService.trackEvent('suppression_evenement', {
			eve_id: evenement.eve_id,
			mag_id: evenement.mag_id
		});
		return this.http.delete<any>(`/magasins/${evenement.mag_id}/evenements/${evenement.eve_id}`);
	}

	attenteEvenement(evenement: Evenement, action: 'creer'|'annuler'|'terminer', eve_date_attente_fin?: string|Date) {
		let tmpParams: any = {
			action: action
		}
		if (eve_date_attente_fin) {
			tmpParams.eve_date_attente_fin = simpleDateToString(eve_date_attente_fin);
		}
		this.analyticsService.trackEvent(`attente_${action}`, {
			eve_id: evenement.eve_id,
			mag_id: evenement.mag_id
		});
		return this.http.post<any>(`/magasins/${evenement.mag_id}/evenements/${evenement.eve_id}/attente`, tmpParams);
	}

	getDetailVentes(params: any) {
		let tmpParams = prepareQueryParams(params);
		this.analyticsService.trackEvent('get_detail_ventes', tmpParams.params);
		return this.http.get<any>(`/cockpit/ventes`, tmpParams);
	}

	export(params: any, mag_id: number) {
		let tmpParams = prepareQueryParamsForDownload(params);
		this.analyticsService.trackEvent('export_evenements', tmpParams.params);
		return this.http.get<any>(`/magasins/${mag_id}/evenements/export`, tmpParams);
	}

	getRdvConfirmes(params: any, mag_id: number) {
		let tmpParams = prepareQueryParams(params);
		this.analyticsService.trackEvent('get_rdv_confirmes', tmpParams.params);
		return this.http.get<any>(`/magasins/${mag_id}/rdv_confirmes`, tmpParams)
		.pipe(
			map((response: any) => {
				if (response.rdv) {
					response.rdv.forEach((one: any) => {
						convertDateFieldsToDate(one);
					});
				}
				return response;
			})
		);
	}

	getOptionsEvenement(params: any, mag_id: number) {
		let tmpParams = prepareQueryParams(params);
		return this.http.get<any>(`/magasins/${mag_id}/options_evenements`, tmpParams)
		.pipe(map((response: any) => {
			response.options_evenements.forEach((one: any) => {
				convertDateFieldsToString(one);
				one.taux_marge_facture = one.taux_marge_facture * 100;
				one.taux_marge_commerciale = one.taux_marge_commerciale * 100;
			});
			return response;
		}));
	}

	exportOptionsEvenement(params: any, mag_id: number) {
		let tmpParams = prepareQueryParamsForDownload(params);
		return this.http.get<any>(`/magasins/${mag_id}/options_evenements/export`, tmpParams)
	}


	putOptionEvenement(optionEvenement: OptionEvenement) {
		return this.http.put<any>(`/magasins/${optionEvenement.mag_id}/options_evenements/${optionEvenement.eve_option_id}`, optionEvenement);
	}

	getOptionEvenement(mag_id: number, eve_option_id: string) {
		return this.http.get<any>(`/magasins/${mag_id}/options_evenements/${eve_option_id}`)
		.pipe(
			map((optionEvenement: OptionEvenement) => {
				convertDateFieldsToString(optionEvenement);
				return optionEvenement;
			})
		);
	}

}
