<form #milieuForm="ngForm">
	<div class="formgrid grid p-fluid">

		<div class="field col-12" *ngIf="!evenement.eve_milieu_modifie_le">
			<label>Milieu WinnerBizz :</label> {{currentMilieu}}
		</div>
		<div class="field col-12">
			<label for="milieu" *ngIf="!evenement.eve_milieu_modifie_le">Votre confirmation</label>
			<label for="milieu" *ngIf="evenement.eve_milieu_modifie_le">Sélectionnez un milieu</label>
			<milieu-selector
				name="milieu"
				[(ngModel)]="selected"
				[nullIfInvalid]="true"
				placeholder="Choisir un milieu..."
				[required]="true"
				[disabled]="loading"
			></milieu-selector>
		</div>

	</div>

	<div class="p-dialog-footer text-right">
		<button
			type="button"
			pButton
			class="btn p-button-sm p-button-text"
			label="Annuler"
			[disabled]="loading"
			(click)="cancel()"
		></button>
		<button
			type="button"
			pButton
			class="btn p-button-sm"
			label="Enregistrer"
			[disabled]="loading || !milieuForm.form.valid"
			(click)="submit()"
		></button>
	</div>
</form>
