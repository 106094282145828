import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { ConfirmationService } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { TooltipModule } from 'primeng/tooltip';
import { TreeNode } from 'primeng/api';

import { TreeTableModule, TreeTable } from '@app/primeng-overrides/treetable';

import { AuthService } from '@app/auth/auth.service';
import { DaterangeSelectorModule } from '@app/daterange-selector';
import { DeclarationMensuelle } from '@app/declaration/declaration.model';
import { DeclarationService } from '@app/declaration/declaration.service';
import { EventManagerService } from '@global/event-manager.service';
import { GlobalModule } from '@global/global.module';
import { IndicateurLabelDisplayModule } from '@app/indicateur/indicateur-label-display';
import { IndicateurTooltipModule } from '@app/indicateur/indicateur-tooltip';
import { IndicateurValueDisplayModule } from '@app/indicateur/indicateur-value-display';
import { MagasinService, Magasin } from '@app/magasin/magasin.service';
import { NumberDisplayModule } from '@helpers/number-display';
import { StorageService } from '@global/storage.service';
import { TitreModuleModule } from '@app/titre-module';

import {
	clone,
	isDefined,
	uid,
	startOfMonth,
	ExtensibleObject,
	simpleComparison
} from '@helpers/utils';


@Component({
	selector: 'controle-declaration-mensuelle',
	templateUrl: './controle-declaration-mensuelle.html',
})
export class ControleDeclarationMensuelleComponent {

	loading: boolean = false;
	exportLoading: boolean = false;

	columns: any[];
	frozen_columns: any[];

	maxDate: Date;
	defaultDate: Date;

	selectedDate: Date;
	params: {
		annee: number,
		mois: number
	}

	declarations: Magasin[];
	regions: any[];

	valueBeforeEdit: any;

	search: string|undefined;
	searchableAttrs = [
		'mag_nom_court',
		'mag_region_commerciale',
	];

	collapsedRows: {[key: string]: any} = {};

	nodes: TreeNode[] = [];

	indicateurs: string[];

	countManquant: number = 0;
	countResteAValider: number = 0;
	total: any;
	nbDeclarationsExistantes: number = 0;

	canValidateAnimateur: boolean;
	canValidateDirection: boolean;

	tooltipEcartPrixAchat: string = `Lorsque le chiffre est négatif cela veut dire que les achats sont plus importants que les achats prévus à la vente, donc baisse de marge.
	Lorsque le chiffre est positif cela veut dire que les achats sont plus intéressant que ce qui était prévu à la vente et que donc la marge facturée est meilleure que la marge prise de commande`;

	constructor(
		private authService: AuthService,
		private confirmationService: ConfirmationService,
		private declarationService: DeclarationService,
		private eventManager: EventManagerService,
		private magasinService: MagasinService,
		private storageService: StorageService,
	) {
		this.maxDate = startOfMonth(new Date());
		this.maxDate.setMonth(this.maxDate.getMonth() -1);
		this.defaultDate = new Date(this.maxDate);
	}

	ngOnInit() {
		this.getParamSet();
		this.indicateurs = this.declarationService.indicateursDeclarationMensuelle;
		this.canValidateAnimateur = this.authService.checkIfHasRight('animateur_region');
		this.canValidateDirection = this.authService.checkIfHasRight('direction_reseau');
		this.prepareColumns();
	}

	indexTracker(index: number, item: any) {
		if (item.node && item.node.uid) return item.node.uid;
		return item.oma_mois || index;
	}

	saveParamSet() {
		this.storageService.setForCurrentState('collapsedRows', this.collapsedRows);
	}

	getParamSet() {
		this.collapsedRows = this.storageService.getForCurrentState('collapsedRows', {});
	}

	filter() {
		let hasVisible: boolean = false;

		this.nodes.forEach((one :any) => {
			if (one.children) {
				one.data.hidden = !this.setVisibleState(one.children, this.search);
			}
		});
		this.nodes = [...this.nodes];
	}

	setVisibleState(nodes: any, search?: string): boolean {
		let hasVisible: boolean = false;
		nodes.forEach((one :any) => {
			if (this.patternMatch(one.data, search)) {
				one.data.hidden = false;
				hasVisible = true;
			}
			else {
				one.data.hidden = true;
			}
		});
		return hasVisible;
	}

	patternMatch(obj: any, search?: string) {
		search = search? search : '';
		const re = new RegExp(`.*${search}.*`, 'i');

		for (let i = 0; i < this.searchableAttrs.length; i++) {
			if (obj[this.searchableAttrs[i]] && re.test(obj[this.searchableAttrs[i]])) {
				return true;
			}
		}
		return false;
	}

	resetFilter() {
		this.search = undefined;
		this.filter();
	}

	onNodeExpand(event: any) {
		let mag_region_commerciale = event.node.data.mag_region_commerciale || undefined;
		if (mag_region_commerciale && isDefined(this.collapsedRows[mag_region_commerciale])) {
			delete this.collapsedRows[mag_region_commerciale];
			this.saveParamSet();
		}
	}

	onNodeCollapse(event: any) {
		let mag_region_commerciale = event.node.data.mag_region_commerciale || undefined;
		if (mag_region_commerciale && !isDefined(this.collapsedRows[mag_region_commerciale])) {
			this.collapsedRows[mag_region_commerciale] = mag_region_commerciale;
			this.saveParamSet();
		}
	}

	onDateChange(event: any) {
		this.selectedDate = event.dateRange[0];
		this.params = {
			annee: this.selectedDate.getFullYear(),
			mois: this.selectedDate.getMonth() + 1
		}

		this.refresh();
	}

	refresh() {
		this.load();
	}

	prepareColumns() {
		this.columns = this.indicateurs.map((ind_code: any) => {
			return {
				ind_code: ind_code,
				class: 'col-indicateur',
				editable: ind_code.startsWith('dme_') // TODO faudrait être sûr de savoir ce qui est éditable
			}
		});
		this.columns.push({
			header: 'Action',
			class: 'col-action'
		});

		this.frozen_columns = [{
			header: ' ',
			class: ''
		}];
	}

	load() {
		if (this.params) {
			// // https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
			Promise.resolve(null).then(() => this.loading = true);
			this.saveParamSet();

			this.declarationService.getDeclarations(this.params)
			.subscribe({
				next: (response: any) => {
					this.declarations = response;
					this.prepareDeclarations(this.declarations);
					let regions = this.magasinService.regroupeMagasinParRegion(this.declarations, 'declarations');
					this.prepareRegions(regions);
					this.nodes = this.convertEntitiesToNodes(this.regions);
					this.calculateTotal();
					this.filter();
				}
			})
			.add(() => {this.loading = false;});
		}

	}

	prepareDeclarations(declarations: any[]) {
		declarations.forEach((declaration: any) => {
			this.prepareDeclaration(declaration);
		});
	}

	prepareDeclaration(declaration: DeclarationMensuelle) {
		declaration.validatable = this.isValidatable(declaration);
	}

	prepareRegions(regions: any[]) {
		this.regions = regions.map((region: any) => {
			return this.prepareEntiteParent(region, region.declarations);
		});
	}

	isValidatable(declaration: DeclarationMensuelle): boolean {
		return !!declaration.dme_date_validation_magasin
			&& (
				this.canValidateAnimateur
				&& !declaration.dme_date_controle_animateur
				&& !declaration.dme_date_controle_direction
				|| this.canValidateDirection
				&& !declaration.dme_date_controle_direction
			)
		;
	}

	prepareEntiteParent(entity: any, declarations: DeclarationMensuelle[]) {
		this.indicateurs.forEach((ind_code: string) => {
			// reset value
			const ind_code_origine = `${ind_code}_origine`;
			if (!entity[ind_code]) entity[ind_code] = 0;
			if (!entity[ind_code_origine]) entity[ind_code_origine] = 0;
			declarations.forEach((declaration: any) => {
				if (this.shouldAddIndicateurValue(ind_code)) {
					if (declaration[ind_code]) {
						entity[ind_code] += declaration[ind_code];
					}
					if (declaration[ind_code_origine]) {
						entity[ind_code_origine] += declaration[ind_code_origine];
					}
				}
			});
		});
		return this.declarationService.calculateTauxDeclaration(entity, true);
	}

	shouldAddIndicateurValue(ind_code: string) {
		return ind_code.startsWith('dme_')
			|| ['objectif_ca_prise_commande_htple', 'nb_vendeurs'].includes(ind_code)
		;
	}

	convertEntitiesToNodes(entities: any[]) {
		return entities.map((entity: any) => {
			let tmpNodeData = new ExtensibleObject();
			if (!entity.mag_id) {
				tmpNodeData.children = entity.declarations.map((declaration: DeclarationMensuelle) => {
					return {
						data: clone(declaration),
						leaf: true,
						uid: uid()
					} as TreeNode;
				})
				delete entity.declarations;
			}

			tmpNodeData.data = clone(entity);
			tmpNodeData.leaf = false;
			tmpNodeData.expanded = !isDefined(this.collapsedRows[entity.mag_region_commerciale]);
			tmpNodeData.uid = uid();
			return tmpNodeData as TreeNode;
		});
	}

	calculateTotal() {
		this.countManquant = 0;
		this.countResteAValider = 0;
		let total = new ExtensibleObject();
		let declarationsExistantes: DeclarationMensuelle[] = [];

		this.nodes.forEach((region: any) => {
			region.children.forEach((declaration: any) => {
				if (!declaration.data.dme_id) {
					this.countManquant++;
				}
				else {
					declarationsExistantes.push(declaration.data);
					if (
						this.canValidateAnimateur
						&& !declaration.data.dme_date_controle_animateur
						||
						this.canValidateDirection
						&& !declaration.data.dme_date_controle_direction
					) {
						this.countResteAValider++;
					}
				}
			});
		});

		this.nbDeclarationsExistantes = declarationsExistantes.length;
		this.total = this.prepareEntiteParent(total, declarationsExistantes);
	}

	validerDeclaration(declaration: DeclarationMensuelle) {
		this.loading = true;
		this.confirmationService.confirm({
			defaultFocus: 'reject',
			message: 'Une fois la déclaration validée, vous ne pourrez plus la modifier. Souhaitez-vous vraiment valider cette déclaration ?',
			rejectButtonStyleClass: 'p-button-text',
			accept: () => {
				this.submitValidation(declaration);
			},
			reject: () => {
				this.loading = false;
			}
		});
	}

	submitValidation(declaration: DeclarationMensuelle) {
		this.loading = true;
		this.declarationService.validerDeclaration(declaration)
		.subscribe({
			next: (response: any) => {
				let edited = this.declarationService.prepareDeclarationMensuelleFromServer(response);
				this.prepareDeclaration(edited);
				this.updateNodeDeclaration(edited);
				this.updateAll(edited);
				this.eventManager.emit('toast', {severity: 'success', summary: 'Déclaration validée'});
			}
		})
		.add(() => { this.loading = false; });
	}

	createDeclaration(mag_id: number) {
		this.loading = true;
		this.declarationService.postDeclarationMensuelleAnimateur(mag_id, this.params.annee, this.params.mois)
		.subscribe({
			next: (response: any) => {
				this.eventManager.emit('toast', {severity: 'success', summary: 'Déclaration créée'});
				this.refresh();
			}
		})
		.add(() => { this.loading = false; });
	}

	updateAll(declaration: DeclarationMensuelle) {
		const nodeRegion = this.nodes.find((node: any) => {
			return node.data.mag_region_commerciale == declaration.mag_region_commerciale;
		});
		if (nodeRegion) {
			this.updateNodeRegion(nodeRegion);
		}
		this.calculateTotal();
	}

	updateNodeDeclaration(declaration: DeclarationMensuelle) {
		const nodeRegion = this.nodes.find((node: any) => {
			return node.data.mag_region_commerciale == declaration.mag_region_commerciale;
		});
		if (nodeRegion && nodeRegion.children) {
			const nodeDeclaration = nodeRegion.children.find((node: any) => {
				return node.data.dme_id == declaration.dme_id;
			});
			if (nodeDeclaration) {
				declaration = this.declarationService.calculateTauxDeclaration(declaration, true);
				nodeDeclaration.data = Object.assign(nodeDeclaration.data, declaration);
			}
		}
	}

	updateNodeRegion(nodeRegion: TreeNode) {
		this.indicateurs.forEach((ind_code: string) => {
			// reset value
			nodeRegion.data[ind_code] = 0;
			if (nodeRegion.children) {
				nodeRegion.children.forEach((nodeDeclaration: any) => {
					if (this.shouldAddIndicateurValue(ind_code)) {
						nodeRegion.data[ind_code] += nodeDeclaration.data[ind_code];
					}
				});
			}
		});
		nodeRegion.data = this.declarationService.calculateTauxDeclaration(nodeRegion.data, true);
		return nodeRegion;
	}

	onEditComplete(event: any) {
		const valueBeforeEdit = this.valueBeforeEdit;
		if (!simpleComparison(event.data, valueBeforeEdit)) {
			this.declarationService.putDeclaration(event.data)
			.subscribe({
				next: (response: any) => {
					this.updateNodeDeclaration(event.data);
					this.updateAll(event.data);
				},
				error: (error: any) => {
					event.data[event.field] = valueBeforeEdit;
				}
			});
		}
	}

	onEditCancel(event: any) {
		event.data[event.field] = this.valueBeforeEdit;
	}

	onEditInit(event: any) {
		this.valueBeforeEdit = clone(event.data[event.field]);
	}

	export() {
		this.exportLoading = true;

		let tmpParams = {
			annee: this.selectedDate.getFullYear(),
			mois: this.selectedDate.getMonth() + 1
		}

		this.declarationService.exportDeclarations(tmpParams)
		.subscribe()
		.add(() => {
			this.exportLoading = false;
		});
	}

}
@NgModule({
	declarations: [
		ControleDeclarationMensuelleComponent
	],
	exports: [
		ControleDeclarationMensuelleComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		CheckboxModule,
		InputTextModule,
		MessageModule,
		TooltipModule,
		TreeTableModule,
		DaterangeSelectorModule,
		GlobalModule,
		IndicateurLabelDisplayModule,
		IndicateurTooltipModule,
		IndicateurValueDisplayModule,
		NumberDisplayModule,
		TitreModuleModule,
	],
})
export class ControleDeclarationMensuelleModule { }
