<div class="grid justify-content-between flex-shrink-0">
	<div class="col formgrid grid p-fluid min-w-20rem">
		<div class="field col-fixed">
			<daterange-selector
				#daterangeSelector
				label="Période"
				singleMode="libre"
				[showPreviousCurrentMonth]="true"
				[useStorage]="true"
				storagePrefix="eve-"
				[disabled]="loading || exportLoading || exclusiveFilters.attente"
				(onDateChange)="onDateChange($event)"
			></daterange-selector>
		</div>

		<div class="field col-fixed">
			<label for="mag_id">Magasin</label>
			<magasin-selector
				name="magasin"
				[(ngModel)]="mag_id"
				[required]="true"
				[mesMagasins]="true"
				[nullIfInvalid]="false"
				[autoSelectFirst]="true"
				[disabled]="loading || exportLoading"
				(ngModelChange)="paramChanged()"
			></magasin-selector>
		</div>

		<div class="field col-fixed flex flex-column justify-content-end">
			<div class="p-inputgroup">
				<input
					type="text"
					pInputText
					[(ngModel)]="params.search"
					(onDebounce)="refresh()"
					[debounce]="500"
					[readonly]="loading || exportLoading"
					placeholder="Rechercher..."
				/>
				<button
					type="button"
					class="p-inputgroup-addon"
					icon="pi pi-times"
					pButton
					label=""
					[disabled]="!params.search || loading || exportLoading"
					(click)="reset()"
				></button>
			</div>
		</div>


		<div class="field p-field-checkbox flex flex-column justify-content-end pt-0 pb-0">
			<div class="mb-1">
				<p-checkbox
					inputId="traite"
					[(ngModel)]="params.traite"
					[binary]="true"
					[disabled]="exportLoading || hasExclusiveFilters()"
					(onChange)="refresh()"
				></p-checkbox>
				<label for="traite">Afficher les traités</label>
			</div>

			<div class="mb-1">
				<p-checkbox
					inputId="masque"
					[(ngModel)]="params.masque"
					[binary]="true"
					[disabled]="exportLoading || hasExclusiveFilters()"
					(onChange)="refresh()"
				></p-checkbox>
				<label for="masque">Afficher les masqués</label>
			</div>

			<div class="mb-1" *ngIf="canSeeOptionsLivrees">
				<p-checkbox
					inputId="livres"
					[(ngModel)]="exclusiveFilters.livres"
					[binary]="true"
					[disabled]="exportLoading"
					(onChange)="toggleExclusiveFilter('livres')"
				></p-checkbox>
				<label for="livres">Options livrées</label>
			</div>
		</div>

		<div class="field p-field-checkbox flex flex-column justify-content-end pt-0 pb-0">
			<div class="mb-1">
				<p-checkbox
					inputId="attente"
					[(ngModel)]="exclusiveFilters.attente"
					[binary]="true"
					[disabled]="exportLoading"
					(onChange)="toggleExclusiveFilter('attente')"
				></p-checkbox>
				<label for="attente">Options en attente</label>
			</div>
			<div class="mb-1">
				<p-checkbox
					inputId="devis_non_vendus"
					[(ngModel)]="exclusiveFilters.devis_non_vendus"
					[binary]="true"
					[disabled]="exportLoading"
					(onChange)="toggleExclusiveFilter('devis_non_vendus')"
				></p-checkbox>
				<label for="devis_non_vendus">Devis non vendus</label>
			</div>
			<div class="mb-1">
				<p-checkbox
					inputId="ventes"
					[(ngModel)]="exclusiveFilters.ventes"
					[binary]="true"
					[disabled]="exportLoading"
					(onChange)="toggleExclusiveFilter('ventes')"
				></p-checkbox>
				<label for="ventes">Ventes</label>
			</div>

		</div>

		<div class="field col-fixed flex align-items-end">
			<div>
				<button
					type="button"
					pButton
					icon="pi pi-refresh"
					[ngClass]="{'p-button-text': !newRemoteEvents, 'button-blink': newRemoteEvents}"
					[loading]="loading"
					[disabled]="exportLoading"
					(click)="refresh()"
					[title]="newRemoteEvents? 'De nouveaux évènements ont été ajoutés' : ''"
				></button>
			</div>

			<div>
				<button
					type="button"
					pButton
					class="p-button-text"
					icon="pi pi-download"
					[disabled]="
						loading
						|| (!exclusiveFilters.livres && !values.length)
						|| (exclusiveFilters.livres && !valuesOptions.length)
					"
					[loading]="exportLoading"
					(click)="export()"
				></button>
			</div>
		</div>

	</div>

	<div class="field col-fixed flex-shrink-0" *ngIf="mag_id">
		<label>Ajouter un évènement</label>
		<div>
			<span class="p-buttonset">
				<button
					type="button"
					pButton
					class=""
					[disabled]="loading || exportLoading"
					(click)="showEntreeLibreDialog(true)"
				>Entrée</button>
				<button
					type="button"
					pButton
					class=""
					[disabled]="loading || exportLoading"
					(click)="showEntreeLibreDialog(false)"
				>Retour</button>
			</span>
		</div>

	</div>

</div>

<div class="min-table-height">
	<!-- EVENEMENTS -->
	<p-table
		#tableEvenements
		[value]="values"
		[lazy]="true"
		(onLazyLoad)="load($event)"
		[lazyLoadOnInit]="false"
		[loading]="loading || exportLoading"
		[paginator]="true"
		[rows]="rows"
		[rowsPerPageOptions]="[10,25,50]"
		[showCurrentPageReport]="true"
		[totalRecords]="totalRecords"
		currentPageReportTemplate="{totalRecords} élément(s)"
		[autoLayout]="true"
		responsiveLayout="scroll"
		scrollDirection="both"
		[scrollable]="true"
		scrollHeight="flex"
		styleClass="p-datatable-sm p-datatable-gridlines table-hover"
		(onEditComplete)="onEditComplete($event)"
		(onEditCancel)="onEditCancel($event)"
		(onEditInit)="onEditInit($event)"
		*ngIf="!exclusiveFilters.livres"
	>

		<ng-template pTemplate="emptymessage">
			<tr>
				<th class="text-center p-3" colspan="100">
					<ng-container *ngIf="!mag_id">Veuillez sélectionner un magasin</ng-container>
					<ng-container *ngIf="mag_id">Aucun résultat pour ces critères</ng-container>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="header">
			<ng-container>
				<tr *ngIf="mag_id && !!values.length">
					<th class="text-center" pFrozenColumn>Date évènement</th>
					<th class="text-center">Vendeur</th>
					<th class="text-center">Client/Milieu</th>
					<th class="text-center">N°&nbsp;Option WINNER</th>
					<th class="text-center">Montant HT&nbsp;HP&nbsp;HL</th>
					<th class="text-center">Prix d'achat&nbsp;HT</th>
					<th class="text-center">
						<indicateur-label-display ind_code="label_eve_mt_marge_commerciale"></indicateur-label-display>
					</th>
					<th class="text-center">
						<indicateur-label-display ind_code="label_eve_taux_marge_commerciale"></indicateur-label-display>
					</th>
					<th class="text-center">
						<indicateur-label-display ind_code="label_eve_entree"></indicateur-label-display>
					</th>
					<th class="text-center">
						<indicateur-label-display ind_code="label_eve_retours"></indicateur-label-display>
					</th>
					<th class="text-center">
						<indicateur-label-display ind_code="label_eve_prise_rdv"></indicateur-label-display>
					</th>
					<th class="text-center">Montant budget&nbsp;TTC</th>
					<th class="text-center">
						<indicateur-label-display ind_code="label_eve_devis"></indicateur-label-display>
					</th>
					<th class="text-center">
						<indicateur-label-display ind_code="label_eve_commande"></indicateur-label-display>
					</th>
					<th class="text-center">
						<indicateur-label-display ind_code="label_eve_b2b"></indicateur-label-display>
					</th>
					<th class="text-center">
						<indicateur-label-display ind_code="label_statut_option"></indicateur-label-display>
					</th>
					<th class="text-center">
						<indicateur-label-display ind_code="label_dnv"></indicateur-label-display>
					</th>
					<th class="text-center" *ngIf="!isVendeur">Action</th>
				</tr>
			</ng-container>
		</ng-template>

		<ng-template pTemplate="body" let-evenement let-rowIndex="rowIndex">
			<tr
				[ngClass]="{
					'eve-masque': evenement.eve_masque,
					'readonly': evenement.readonly,
					'eve-traite': evenement.eve_entree||evenement.eve_retours||evenement.eve_devis||evenement.eve_commande||evenement.eve_date_attente_fin,
					'eve-attente': evenement.eve_date_attente_debut && !evenement.eve_date_attente_fin,
					'eve-updated': evenement.updated,
					'eve-deleted': evenement.deleted
				}"
				id="evenement-{{evenement.eve_id}}"
				title="{{evenement.label}}"
				*ngVar="evenement.eve_type as eve_type"
			>
				<td class="nowrap" pFrozenColumn>
					{{ evenement.eve_date_evenement | date: 'dd/MM/yyyy HH:mm'}}
				</td>
				<td class="">
					<!-- vendeur -->
					<div class="flex align-items-baseline" title="{{evenement.uti_prenom}} {{evenement.uti_nom}}">
						{{ evenement.uti_prenom }} {{ evenement.uti_nom }}
						<button
							type="button"
							pButton
							icon="pi pi-pencil"
							class="p-button-xs p-button-text"
							(click)="showVendeurDialog(evenement)"
							*ngIf="canEditVendeur(evenement)"
						></button>
					</div>
					<div class="text-sm" *ngIf="evenement.eve_date_attente_debut && !evenement.eve_date_attente_fin">
						Mis en attente le&nbsp;:
						{{ evenement.eve_date_attente_debut | date: 'dd/MM/yyyy'}}
					</div>
					<div class="text-sm" *ngIf="evenement.eve_date_attente_fin">
						Validé le&nbsp;:
						{{ evenement.eve_date_attente_fin | date: 'dd/MM/yyyy'}}
					</div>
				</td>

				<td>
					<!-- client/milieu -->
					<div>{{ evenement.eve_client }}</div>
					<div class="flex align-items-baseline text-sm white-space-nowrap" *ngIf="eve_type == 'O'">
						<div class="inline">
							<i class="pi pi-exclamation-triangle text-error align-bottom"
								*ngIf="eve_type == 'O' && !evenement.eve_milieu_modifie_le"
								title="Milieu non confirmé"
							></i>
							<span *ngIf="evenement.eve_milieu">
								{{ evenement.eve_milieu }}
							</span>
							<span *ngIf="!evenement.eve_milieu">
								Aucun milieu
							</span>
						</div>
						<button
							type="button"
							pButton
							icon="pi pi-pencil"
							class="p-button-xs p-button-text"
							(click)="showMilieuDialogClick(evenement)"
							*ngIf="canEditMilieu(evenement)"
						></button>
					</div>
					<div class="text-sm" *ngIf="eve_type == 'E'">
						{{ eve_type | typeEvenement }}
					</div>
					<div class="text-sm" *ngIf="eve_type == 'L'">
						{{ eve_type | typeEvenement }}
					</div>
					<div class="text-sm" *ngIf="eve_type == 'V'">
						{{ eve_type | typeEvenement }}
					</div>
				</td>

				<td class="text-right nowrap">
					{{ evenement.eve_option_id }}
				</td>

				<td class="text-right nowrap">
					<!-- montant htple -->
					<indicateur-value-display ind_code="mt_achats_factures" [value]="evenement.eve_mt_vente_total_htple"></indicateur-value-display>
				</td>
				<td class="text-right nowrap">
					<!-- prix achat HT -->
					<indicateur-value-display ind_code="mt_achat_ht" [value]="evenement.eve_mt_achat_ht"></indicateur-value-display>
				</td>
				<td class="text-right nowrap">
					<!-- montant marge -->
					<indicateur-value-display
						ind_code="mt_marge_facture_ht"
						[value]="evenement.eve_mt_marge_facture_ht"
						*ngIf="!evenement.eve_date_cr"
					></indicateur-value-display>
					<span *ngIf="evenement.eve_date_cr && evenement.eve_mt_marge_facture_ht" title="La commande a été modifiée">N/A</span>
				</td>
				<td class="text-right nowrap">
					<!-- marge -->
					<indicateur-value-display
						ind_code="taux_marge_commerciale"
						[value]="evenement.eve_taux_marge_commerciale"
						*ngIf="!evenement.eve_date_cr"
					></indicateur-value-display>
					<span *ngIf="evenement.eve_date_cr && evenement.eve_taux_marge_commerciale" title="La commande a été modifiée">N/A</span>
				</td>
				<td class="text-center nowrap">
					<!-- entree -->
					<p-checkbox
						[(ngModel)]="evenement.eve_entree"
						[ngModelOptions]="{standalone: true}"
						[binary]="true"
						[disabled]="disableCheckboxEntree(evenement)"
						(onChange)="onEditCheckbox($event, evenement, 'eve_entree')"
						*ngIf="showCheckboxEntree(evenement)"
					></p-checkbox>
				</td>
				<td class="text-center nowrap">
					<!-- retour -->
					<p-checkbox
						[(ngModel)]="evenement.eve_retours"
						[ngModelOptions]="{standalone: true}"
						[binary]="true"
						[disabled]="disableCheckboxRetour(evenement)"
						(onChange)="onEditCheckbox($event, evenement, 'eve_retours')"
						*ngIf="showCheckboxRetour(evenement)"
					></p-checkbox>
				</td>
				<td class="text-center nowrap">
					<!-- rdv -->
					<p-checkbox
						[(ngModel)]="evenement.eve_prise_rdv"
						[ngModelOptions]="{standalone: true}"
						[binary]="true"
						[disabled]="disableCheckboxRdv(evenement)"
						(onChange)="onEditCheckbox($event, evenement, 'eve_prise_rdv')"
						*ngIf="evenement.eve_entree"
					></p-checkbox>
				</td>

				<ng-container *ngIf="!disableBudgetTTC(evenement)">
					<!-- budget ttc -->
					<td class="text-right cell-input nowrap" [pEditableColumn]="evenement" [pEditableColumnRowIndex]="rowIndex" pEditableColumnField="eve_mt_budget_ttc">
						<p-cellEditor>
							<ng-template pTemplate="input">
								<div class="p-input-icon-right input-container">
									<i class="pi ff-reset">€</i>
									<input
										type="text"
										pInputText
										gzInputNumberFormatter
										[(ngModel)]="evenement.eve_mt_budget_ttc"
										[ngModelOptions]="{standalone: true}"
										[decimals]="2"
										[currency]="true"
										class="text-right"
									>
								</div>
							</ng-template>
							<ng-template pTemplate="output">
								<indicateur-value-display ind_code="mt_budget_ttc" [value]="evenement.eve_mt_budget_ttc"></indicateur-value-display>
								<i class="pi pi-pencil text-primary text-sm pl-2"></i>
							</ng-template>
						</p-cellEditor>
					</td>
				</ng-container>

				<ng-container *ngIf="disableBudgetTTC(evenement)">
					<!-- budget ttc readonly -->
					<td class="text-right nowrap">
						<indicateur-value-display ind_code="mt_budget_ttc" [value]="evenement.eve_mt_budget_ttc"></indicateur-value-display>
					</td>
				</ng-container>

				<td class="text-center nowrap">
					<!-- devis -->
					<p-checkbox
						[(ngModel)]="evenement.eve_devis"
						[ngModelOptions]="{standalone: true}"
						[binary]="true"
						[disabled]="disableCheckboxDevis(evenement)"
						(onChange)="onEditCheckbox($event, evenement, 'eve_devis')"
						*ngIf="showCheckboxDevis(evenement)"
					></p-checkbox>
				</td>
				<td class="text-center nowrap">
					<!-- commande -->
					<p-checkbox
						[(ngModel)]="evenement.eve_commande"
						[ngModelOptions]="{standalone: true}"
						[binary]="true"
						[disabled]="disableCheckboxCommande(evenement)"
						(onChange)="onEditCheckbox($event, evenement, 'eve_commande')"
						*ngIf="showCheckboxCommande(evenement)"
					></p-checkbox>
				</td>
				<td class="text-center nowrap">
					<!-- b2b -->
					<p-checkbox
						[(ngModel)]="evenement.eve_b2b"
						[ngModelOptions]="{standalone: true}"
						[binary]="true"
						[disabled]="disableCheckboxB2B(evenement)"
						(onChange)="onEditCheckbox($event, evenement, 'eve_b2b')"
						*ngIf="showCheckboxB2B(evenement)"
					></p-checkbox>
				</td>

				<td class="text-center nowrap">
					<!-- statut-option -->
					<statut-option-selector
						[(ngModel)]="evenement.option_evenement.ope_statut"
						[ngModelOptions]="{standalone: true}"
						(ngModelChange)="updateOptionEvenement(evenement.option_evenement)"
						[silentInitialChangeIfSame]="true"
						placeholder=" "
						[required]="false"
						*ngIf="evenement.option_evenement"
					></statut-option-selector>
				</td>
				<td class="text-center nowrap">
					<!-- commentaire dnv -->
					<button
						type="button"
						pButton
						icon="pi pi-comment"
						class="p-button-sm large-icon p-button-text"
						[ngClass]="{
							'p-button-success': evenement.option_evenement.ope_commentaire,
							'p-button-plain': !evenement.option_evenement.ope_commentaire
						}"
						(click)="showCommentaireDNVDialog(evenement)"

						pTooltip="{{formatCommentaireDNV(evenement.option_evenement)}}"
						tooltipPosition="left"
						tooltipStyleClass="tooltip-large"
						*ngIf="evenement.option_evenement"
					></button>
				</td>


				<td class="nowrap" *ngIf="!isVendeur">
					<!-- actions -->
					<button
						type="button"
						pButton
						[icon]="evenement.eve_masque? 'pi pi-eye' : 'pi pi-eye-slash' "
						[title]="evenement.eve_masque? 'Démasquer' : 'Masquer' "
						class="p-button-sm large-icon p-button-text"
						[disabled]="disableCheckboxMasque(evenement)"
						[tooltipDisabled]="!disableCheckboxMasque(evenement)"
						pTooltip="Pour être masqué, l'évènement ne doit pas avoir de coche"
						tooltipPosition="left"
						(click)="toggleMasqueEvenement(evenement)"
						*ngIf="showCheckboxMasque(evenement)"
					></button>

					<button
						type="button"
						pButton
						icon="pi pi-clock"
						title="Mettre en attente"
						class="p-button-sm large-icon p-button-text"
						(click)="mettreEnAttenteClick(evenement)"
						*ngIf="!evenement.readonly && canMettreEnAttente(evenement)"
					></button>

					<button
						type="button"
						pButton
						icon="pi pi-clock"
						title="Terminer l'attente"
						class="p-button-sm large-icon p-button-text button-strike"
						(click)="terminerAttenteMenu.toggle($event)"
						*ngIf="canTerminerAttente(evenement)"
					></button>
					<p-menu #terminerAttenteMenu [popup]="true" [model]="evenement.terminerAttenteMenuItems" appendTo="body"></p-menu>

					<button
						type="button"
						pButton
						icon="pi pi-trash"
						class="p-button-sm large-icon p-button-text p-button-danger"
						(click)="deleteEvenement(evenement)"
						*ngIf="!evenement.readonly && canSupprimer(evenement)"
					></button>

				</td>
			</tr>
		</ng-template>

	</p-table>

	<!-- OPTIONS -->
	<p-table
		#tableEvenements
		[value]="valuesOptions"
		[lazy]="true"
		(onLazyLoad)="load($event)"
		[lazyLoadOnInit]="false"
		[loading]="loading || exportLoading"
		[paginator]="true"
		[rows]="rows"
		[rowsPerPageOptions]="[10,25,50]"
		[showCurrentPageReport]="true"
		[totalRecords]="totalRecordsOptions"
		currentPageReportTemplate="{totalRecords} élément(s)"
		[autoLayout]="true"
		responsiveLayout="scroll"
		scrollDirection="both"
		[scrollable]="true"
		scrollHeight="flex"
		styleClass="p-datatable-sm p-datatable-gridlines table-hover"
		*ngIf="exclusiveFilters.livres"
	>
		<ng-template pTemplate="emptymessage">
			<tr>
				<th class="text-center p-3" colspan="100">
					<ng-container *ngIf="!mag_id">Veuillez sélectionner un magasin</ng-container>
					<ng-container *ngIf="mag_id">Aucun résultat pour ces critères</ng-container>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="header">
			<ng-container>
				<tr *ngIf="mag_id && !!valuesOptions.length">
					<th class="text-center" pFrozenColumn>Date Livraison</th>
					<th class="text-center">Vendeur</th>
					<th class="text-center">Client/Milieu</th>
					<th class="text-center">N°&nbsp;Option WINNER</th>
					<th class="text-center">Montant HT&nbsp;HP&nbsp;HL</th>
					<th class="text-center">Prix d'achat&nbsp;HT Commande</th>
					<th class="text-center">Montant Marge Commande</th>
					<th class="text-center">% Marge Commande</th>
					<th class="text-center">Montant budget&nbsp;TTC</th>
					<th class="text-center">
						<indicateur-label-display ind_code="label_eve_b2b"></indicateur-label-display>
					</th>
					<th class="text-center">Montant HTPLE Facturé</th>
					<th class="text-center">Prix d'achat HT Facturé</th>
					<th class="text-center">% Marge Facturé</th>

					<th class="text-center">
						<indicateur-label-display ind_code="label_statut_option"></indicateur-label-display>
					</th>
					<th class="text-center">
						<indicateur-label-display ind_code="label_dnv"></indicateur-label-display>
					</th>
				</tr>
			</ng-container>
		</ng-template>

		<ng-template pTemplate="body" let-option let-rowIndex="rowIndex">
			<tr
				[ngClass]="{'eve-updated': option.updated}"
				id="option-{{option.eve_option_id}}"
			>
				<td class="nowrap" pFrozenColumn>
					{{ option.ope_date_livraison_reelle | date: 'dd/MM/yyyy'}}
				</td>
				<td class="">
					<!-- vendeur -->
					<!-- <div class="flex align-items-baseline" title="{{option.uti_prenom}} {{option.uti_nom}}">
						{{ option.uti_prenom }} {{ option.uti_nom }} -->
					<div class="flex align-items-baseline" title="{{option.uti_prenom_nom}}">
						{{ option.uti_prenom_nom }}
					</div>
				</td>

				<td>
					<!-- client/milieu -->
					<div>{{ option.eve_client }}</div>
					<div class="flex align-items-baseline text-sm">
						{{ option.eve_milieu }}
					</div>
				</td>

				<td class="text-right nowrap">
					{{ option.eve_option_id }}
				</td>

				<td class="text-right nowrap">
					<!-- montant htple -->
					<indicateur-value-display ind_code="mt_achats_factures" [value]="option.mt_vente_total_htple"></indicateur-value-display>
				</td>
				<td class="text-right nowrap">
					<!-- prix achat HT -->
					<indicateur-value-display ind_code="mt_achat_ht" [value]="option.mt_achat_ht"></indicateur-value-display>
				</td>
				<td class="text-right nowrap">
					<!-- montant marge -->
					<indicateur-value-display ind_code="mt_marge_facture_ht" [value]="option.mt_marge_facture_ht"></indicateur-value-display>
				</td>
				<td class="text-right nowrap">
					<!-- marge -->
					<indicateur-value-display ind_code="taux_marge_commerciale" [value]="option.taux_marge_commerciale"></indicateur-value-display>
				</td>

				<!-- budget ttc readonly -->
				<td class="text-right nowrap">
					<indicateur-value-display ind_code="mt_budget_ttc" [value]="option.mt_budget_ttc"></indicateur-value-display>
				</td>

				<td class="text-center nowrap">
					<!-- b2b -->
					<p-checkbox
						[(ngModel)]="option.eve_b2b"
						[ngModelOptions]="{standalone: true}"
						[binary]="true"
						[disabled]="true"
					></p-checkbox>
				</td>

				<td class="text-right">
					<!-- Montant HTPLE Facturé -->
					<indicateur-value-display ind_code="mt_achats_factures" [value]="option.ope_mt_facture_htple"></indicateur-value-display>
				</td>
				<td class="text-right">
					<!-- Prix d'achat HT Facturé -->
					<indicateur-value-display ind_code="mt_achats_factures" [value]="option.ope_mt_achat_facture_ht"></indicateur-value-display>
				</td>
				<td class="text-right">
					<!-- % Marge Facturé -->
					<indicateur-value-display ind_code="taux_marge_commerciale" [value]="option.taux_marge_facture"></indicateur-value-display>
				</td>

				<td class="text-center nowrap">
					<!-- statut-option -->
					{{option.ope_statut}}
				</td>
				<td class="text-center nowrap">
					<!-- commentaire dnv -->
					<i
						class="pi pi-comment"
						[ngClass]="{'text-success': option.ope_commentaire}"
						pTooltip="{{formatCommentaireDNV(option)}}"
						tooltipPosition="left"
						tooltipStyleClass="tooltip-large"
					></i>
				</td>
			</tr>
		</ng-template>

	</p-table>


</div>
