import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AnalyticsService } from '@global/analytics.service';
import {
	calculateTaux,
	calculateTauxMarge,
	clone,
	convertDateFieldsToDate,
	createDownloadFromHttpResponse,
	isEmpty,
	percentTauxAndEvo,
	prepareQueryParams,
	prepareQueryParamsForDownload,
} from '@helpers/utils';

import { DeclarationMensuelle } from './declaration.model';

@Injectable({ providedIn: 'root' })
export class DeclarationService {


	indicateursDeclarationMensuelle: string[] = [
		'dme_nb_entrees',
		'dme_nb_devis',
		'dme_nb_ventes',
		'nb_vendeurs',
		'dme_mt_achats_commandes_ht',
		'dme_ca_prise_commande_htple',
		'dme_ca_prise_commande_htple_b2b',
		'objectif_ca_prise_commande_htple',
		'taux_mt_ventes_htple_mt_objectif_htple',
		'dme_mt_achats_factures',
		'dme_mt_sav',
		'dme_nb_sav',
		'taux_nb_ventes_nb_entrees',
		'taux_nb_ventes_nb_devis',
		'taux_nb_devis_nb_entrees',
		'dme_mt_marge_facture_ht',
		'dme_mt_facture_ht',
		'avg_vente_htple',
		'taux_marge_commerciale',
		'dme_ecart_prix_achat',
		'taux_mt_sav_ca_prise_commande_htple',
		'taux_nb_sav_nb_ventes',
	];

	constructor(
		private analyticsService: AnalyticsService,
		private datePipe: DatePipe,
		private http: HttpClient,
	) {

	}

	public prepareDeclarationsMensuellesFromServer(declarations: DeclarationMensuelle[]) {
		declarations.forEach((one: DeclarationMensuelle, index: number) => {
			declarations[index] = this.prepareDeclarationMensuelleFromServer(one);
		});
		return declarations;
	}

	public prepareDeclarationMensuelleFromServer(declaration: DeclarationMensuelle) {
		let tmp = clone(declaration);

		convertDateFieldsToDate(tmp);
		tmp.date_declaration = new Date(tmp.dme_annee, tmp.dme_mois -1, 1);
		this.calculateTauxDeclaration(tmp, true);

		tmp.infoValidation = 'Déclaration non validée par le magasin';
		if (tmp.dme_date_validation_magasin) {
			const dateValidationString = this.datePipe.transform(tmp.dme_date_validation_magasin, 'dd/MM/yyyy HH:mm:ss');
			tmp.infoValidation = `• Validation magasin le : ${dateValidationString} par ${tmp.uti_validation_magasin_prenom} ${tmp.uti_validation_magasin_nom}`;

			if (tmp.dme_date_controle_animateur) {
				const dateControleString = this.datePipe.transform(tmp.dme_date_controle_animateur, 'dd/MM/yyyy HH:mm:ss');
				tmp.infoValidation = `${tmp.infoValidation}\n• Contrôle animateur le : ${dateControleString} par ${tmp.uti_controle_animateur_prenom} ${tmp.uti_controle_animateur_nom}`;
			}

			if (tmp.dme_date_controle_direction) {
				const dateControleString = this.datePipe.transform(tmp.dme_date_controle_direction, 'dd/MM/yyyy HH:mm:ss');
				tmp.infoValidation = `${tmp.infoValidation}\n• Contrôle direction le : ${dateControleString} par ${tmp.uti_controle_direction_prenom} ${tmp.uti_controle_direction_nom}`;
			}
		}

		return tmp;
	}

	public calculateTauxDeclaration(declaration: DeclarationMensuelle|any, percent: boolean = false) {
		calculateTaux(declaration, 'dme_ca_prise_commande_htple', 'objectif_ca_prise_commande_htple', 'taux_mt_ventes_htple_mt_objectif_htple');
		calculateTaux(declaration, 'dme_mt_sav', 'dme_ca_prise_commande_htple', 'taux_mt_sav_ca_prise_commande_htple');
		calculateTaux(declaration, 'dme_nb_ventes', 'dme_nb_entrees', 'taux_nb_ventes_nb_entrees');
		calculateTaux(declaration, 'dme_nb_devis', 'dme_nb_entrees', 'taux_nb_devis_nb_entrees');
		calculateTaux(declaration, 'dme_nb_ventes', 'dme_nb_devis', 'taux_nb_ventes_nb_devis');
		calculateTaux(declaration, 'dme_nb_sav', 'dme_nb_ventes', 'taux_nb_sav_nb_ventes');
		calculateTauxMarge(declaration, 'dme_ca_prise_commande_htple', 'dme_mt_achats_commandes_ht', 'taux_marge_commerciale');

		if (declaration.cumul) {
			calculateTaux(declaration.cumul, 'ca_prise_commande_htple', 'objectif_ca_prise_commande_htple', 'taux_mt_ventes_htple_mt_objectif_htple');
		}

		if (!declaration.avg_vente_htple) {
			calculateTaux(declaration, 'dme_ca_prise_commande_htple', 'dme_nb_ventes', 'avg_vente_htple');
		}

		if (percent) percentTauxAndEvo(declaration);
		return declaration;
	}

	public prepareDeclarationMensuelleCreation(declaration: DeclarationMensuelle) {
		let tmp = {
			dme_annee: declaration.dme_annee,
			dme_mois: declaration.dme_mois,
			effectifs: declaration.effectifs
		}
		return tmp;
	}


	public getDeclarationMensuelleACreer(mag_id: number): Observable<any> {
		this.analyticsService.trackEvent('get_declarations_a_creer', {mag_id: mag_id});
		return this.http.get<any>(`/magasins/${mag_id}/declarations_mensuelles_a_creer`)
		.pipe(map(
			(response: any) => {
				if (!isEmpty(response)) {
					return this.prepareDeclarationMensuelleFromServer(response);
				}
				return null;
			}
		));
	}

	public postDeclarationMensuelle(mag_id: number, declaration: DeclarationMensuelle): Observable<any> {
		this.analyticsService.trackEvent('creation_declaration', {
			mag_id: declaration.mag_id,
			dme_id: declaration.dme_id,
			dme_annee: declaration.dme_annee,
			dme_mois: declaration.dme_mois
		});
		return this.http.post<any>(`/magasins/${mag_id}/declarations_mensuelles`, this.prepareDeclarationMensuelleCreation(declaration));
	}

	public postDeclarationMensuelleAnimateur(mag_id: number, dme_annee: number, dme_mois: number): Observable<any> {
		const data = {
			mag_id: mag_id,
			dme_annee: dme_annee,
			dme_mois: dme_mois
		}
		return this.http.post<any>(`/magasins/${mag_id}/declarations_mensuelles_animateur`, data);
	}

	public getDeclarations(params: any) {
		let tmpParams = prepareQueryParams(params);
		this.analyticsService.trackEvent('get_declarations_a_controler', tmpParams.params);
		return this.http.get<any>(`/declarations_mensuelles`, tmpParams)
		.pipe(map(
			(response: any) => {
				if (!isEmpty(response)) {
					return this.prepareDeclarationsMensuellesFromServer(response);
				}
				return response;
			}
		));
	}

	public exportDeclarations(params: any) {
		let tmpParams = prepareQueryParamsForDownload(params);
		this.analyticsService.trackEvent('export_declarations', tmpParams.params);
		return this.http.get<any>(`/declarations_mensuelles/export`, tmpParams)
		.pipe(map(
			(response: any) => {
				createDownloadFromHttpResponse(response);
				return true;
			}
		));
	}

	public putDeclaration(declaration: DeclarationMensuelle) {
		this.analyticsService.trackEvent('edit_declaration', {
			mag_id: declaration.mag_id,
			dme_id: declaration.dme_id,
			dme_annee: declaration.dme_annee,
			dme_mois: declaration.dme_mois
		});
		return this.http.put<any>(`/declarations_mensuelles/${declaration.dme_id}`, declaration);
	}

	public validerDeclaration(declaration: DeclarationMensuelle) {
		this.analyticsService.trackEvent('controle_declaration', {
			mag_id: declaration.mag_id,
			dme_id: declaration.dme_id,
			dme_annee: declaration.dme_annee,
			dme_mois: declaration.dme_mois
		});
		return this.http.post<any>(`/declarations_mensuelles/${declaration.dme_id}/validation`, null);
	}
}
