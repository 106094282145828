import { Component, NgModule, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';

import { NgForm, FormsModule } from '@angular/forms';

import { UIRouterModule } from '@uirouter/angular';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from '@app/primeng-overrides/dynamicdialog';
import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { LazyLoadEvent } from 'primeng/api';
import { MessageModule } from 'primeng/message';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule, Table } from '@app/primeng-overrides/table';
import { TagModule } from 'primeng/tag';

import { AchatsFournisseursService } from '@app/moa/achats-fournisseurs/achats-fournisseurs.service';
import { AuthService } from '@app/auth/auth.service';
import { ContratAmortissementExposition } from '@app/moa/achats-fournisseurs/achats-fournisseurs.model';
import { ContratAmortissementExpositionDialogComponent } from '@app/moa/achats-fournisseurs/contrat-amortissement-exposition-dialog';
import { EventManagerService } from '@global/event-manager.service';
import { GlobalModule } from '@global/global.module';
import { MagasinSelectorModule } from '@app/magasin/magasin-selector';
import { NumberDisplayModule } from '@app/_helpers/number-display';
import { PrintButtonModule } from '@app/print-button';
import { StorageService } from '@global/storage.service';
import { TitreModuleModule } from '@app/titre-module';


@Component({
	selector: 'contrats-amortissements-expositions',
	templateUrl: './contrats-amortissements-expositions.html',
	providers: [],
})
export class ContratsAmortissementsExpositionsComponent {

	@ViewChild('tableContrats', {static: true}) table: Table;

	loading: boolean = false;

	droitsCanEdit: string[] = ['animateur', 'manager'];
	canEdit: boolean = false;

	totalRecords: number = 0;
	rows: number = 20;
	totaux: {mt_a_amortir: number, objectif: number, mt_reste_a_faire: number}|null = null;
	values: ContratAmortissementExposition[] = [];

	mag_id: number;
	params: any = {
		contrats_termines: null
	}

	today: Date = new Date();

	constructor(
		private achatsFournisseursService: AchatsFournisseursService,
		private authService: AuthService,
		private confirmationService: ConfirmationService,
		private datePipe: DatePipe,
		private dialogService: DialogService,
		private eventManager: EventManagerService,
		private storageService: StorageService,
	) {

		this.canEdit = this.authService.checkIfHasRight(this.droitsCanEdit);

	}

	ngOnInit() {
		this.getParamSet();
		// this.load(); // sera lancé par le magasin-selector
	}

	refresh(stayOnPage?: boolean) {
		if (!stayOnPage) this.table._first = 0;
		this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
	}

	getParamSet() {
		let tableParams = this.storageService.getForCurrentState('tableParams', undefined, true);
		this.rows = this.storageService.getForCurrentState('tableRows', this.rows);
		if (tableParams) {
			for (let prop in this.params) {
				if (tableParams[prop]) this.params[prop] = tableParams[prop];
			}
			if (tableParams.first) this.table.first = tableParams.first;
		}
		this.mag_id = this.storageService.getForCurrentState('mag_id', this.mag_id);
	}

	saveParamSet() {
		if (this.table) {
			let tableParams = Object.assign({}, this.params, {
				first: this.table.first,
				rows: this.table.rows
			});
			this.storageService.setForCurrentState('tableParams', tableParams, true);
			this.storageService.setForCurrentState('tableRows', this.table.rows);
		}
		this.storageService.setForCurrentState('mag_id', this.mag_id);
	}

	load(event: any) {

		if (this.mag_id) {
			// https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
			Promise.resolve(null).then(() => this.loading = true);
			let tmpParams = Object.assign({}, event)
			this.saveParamSet();

			this.achatsFournisseursService.getContratsAmortissementsExpositions(this.mag_id, tmpParams)
			.subscribe({
				next: (response: any) => {
					this.totalRecords = response.total;
					this.values = response.contrats;
					this.totaux = (this.values.length > 1)? response.totaux : null;
				}
			})
			.add(() => { this.loading = false; });
		}

	}

	showContratAmortissementExpositionDialog(contrat?: ContratAmortissementExposition) {
		let header: string = contrat? `Contrat ` : 'Nouveau contrat';
		if (!contrat) {
			contrat = new ContratAmortissementExposition(this.mag_id);
		}
		else {
			const dateContrat = this.datePipe.transform(contrat.cae_date_debut_contrat, 'MMMM yyyy');
			header = `${header} ${contrat.fournisseur.foe_nom} ${dateContrat}`;
		}
		const ref = this.dialogService.open(ContratAmortissementExpositionDialogComponent, {
			data: {
				contratAmortissementExposition: contrat,
			},
			header: header,
			styleClass: 'max-w-40rem dialog-no-scroll'
		});

		ref.onClose.subscribe((dialogResult: any) =>{
			if (dialogResult) {
				this.refresh();
			}
		});
	}

	deleteContratAmortissementExposition(contrat: ContratAmortissementExposition) {
		const dateContrat = this.datePipe.transform(contrat.cae_date_debut_contrat, 'mediumDate');
		this.confirmationService.confirm({
			defaultFocus: 'reject',
			message: `Souhaitez-vous vraiment supprimer le contrat du ${dateContrat} de ${contrat.fournisseur.foe_nom} ?`,
			rejectButtonStyleClass: 'p-button-text',
			accept: () => {
				this.achatsFournisseursService.deleteContratAmortissementExposition(contrat)
				.subscribe({
					next: () => {
						this.eventManager.emit('toast', {severity: 'success', summary: 'Contrat supprimé'});
						this.refresh();
					}
				})
			},
		});
	}




}
@NgModule({
	declarations: [
		ContratsAmortissementsExpositionsComponent
	],
	exports: [
		ContratsAmortissementsExpositionsComponent
	],
	imports: [
		CommonModule,
		UIRouterModule,
		FormsModule,
		ButtonModule,
		CheckboxModule,
		DropdownModule,
		InputTextModule,
		InputTextareaModule,
		MessageModule,
		RadioButtonModule,
		SelectButtonModule,
		TableModule,
		TagModule,
		GlobalModule,
		MagasinSelectorModule,
		NumberDisplayModule,
		PrintButtonModule,
		TitreModuleModule,
	],
})
export class ContratsAmortissementsExpositionsModule { }
