<h1 class="sm:flex align-items-center flex-shrink-0">
	<titre-module>Contrats amortissements expositions</titre-module>

	<print-button-simple [track]="false" [track_mag_id]="mag_id"></print-button-simple>
</h1>

<div class="grid flex-shrink-0">
	<p-table
		[value]="[1]"
		responsiveLayout="scroll"
		class="col-fixed mb-2"
		styleClass="p-datatable-sm p-datatable-gridlines table-auto"
	>
		<ng-template pTemplate="body">
			<tr>
				<td class="cell-th">Magasin</td>
				<td class="text-right">
					<magasin-selector
						#magasinSelector
						name="magasin"
						[(ngModel)]="mag_id"
						[nullOption]="false"
						[nullIfInvalid]="false"
						[mesMagasins]="false"
						[autoSelectFirst]="true"
						(ngModelChange)="refresh()"
						class="hide-print"
					></magasin-selector>
					<span class="show-print">{{magasinSelector.getEntityName(true)}}</span>
				</td>
				<td class="clear-cell hide-print">
					<button
						type="button"
						pButton
						icon="pi pi-refresh"
						class="p-button-text hide-print"
						[disabled]="loading"
						[loading]="loading"
						(click)="refresh()"
					></button>
				</td>
			</tr>

		</ng-template>
	</p-table>

</div>


<div class="min-table-height">

	<p-table
		#tableContrats
		[value]="values"
		[lazy]="true"
		(onLazyLoad)="load($event)"
		[loading]="loading"
		responsiveLayout="scroll"
		[paginator]="true"
		[rows]="rows"
		[rowsPerPageOptions]="[20,50,{showAll: 'Tous'}]"
		[showCurrentPageReport]="true"
		[totalRecords]="totalRecords"
		currentPageReportTemplate="{totalRecords} élément(s)"
		[autoLayout]="true"
		responsiveLayout="scroll"
		responsiveLayout="scroll"
		scrollDirection="both"
		[scrollable]="true"
		scrollHeight="flex"
		styleClass="p-datatable-sm p-datatable-gridlines table-hover table-incidents-prestataire"
	>
		<ng-template pTemplate="emptymessage">
			<tr>
				<th class="text-center p-3" colspan="100">Aucun résultat pour ces critères</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="header">
			<tr>
				<th class="text-center">Fournisseur</th>
				<th class="text-center">Intitulé</th>
				<th class="text-center nowrap">Premier contrat</th>
				<th class="text-center nowrap">Date début contrat</th>
				<th class="text-center nowrap">Date fin contrat</th>
				<th class="text-center">Montant à amortir</th>
				<th class="text-center nowrap">Objectif</th>
				<th class="text-center">Taux d'amortissement (réalisé)</th>
				<th class="text-center">Reste à faire</th>
				<th class="text-center">Fin de contrat</th>
				<th class="nowrap hide-print">
					<button
						type="button"
						pButton
						icon="pi pi-plus"
						class="p-button p-button-sm"
						label="Ajouter"
						(click)="showContratAmortissementExpositionDialog()"
						[disabled]="loading || !mag_id"
						*ngIf="canEdit"
					></button>
				</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="body" let-contrat let-rowIndex="rowIndex">
			<tr class="row-total font-bold" *ngIf="rowIndex == 0 && totaux">
				<td class="text-left" colspan="5">Total contrats en cours</td>
				<td class="text-right">
					<number-display [value]="totaux.mt_a_amortir" type="currency"></number-display>
				</td>
				<td class="text-right">
					<number-display [value]="totaux.objectif" type="currency"></number-display>
				</td>
				<td class=""></td>
				<td class="text-right">
					<number-display [value]="totaux.mt_reste_a_faire" type="currency"></number-display>
				</td>
				<td class="" colspan="2"></td>
			</tr>
			<tr class="" [ngClass]="{'disabled': contrat.cae_contrat_termine}">
				<td class="text-left">
					{{contrat.foe_code}}
				</td>
				<td class="text-center">
					{{contrat.cae_intitule}}
				</td>
				<td class="text-center nowrap">
					<!-- <i class="pi pi-checkmark" *ngIf="contrat.cae_premier_contrat"></i> -->
					<span *ngIf="contrat.cae_premier_contrat">Oui</span>
					<span *ngIf="!contrat.cae_premier_contrat">Non</span>
				</td>
				<td class="text-center nowrap">
					{{contrat.cae_date_debut_contrat | date:'MMM yyyy'| titlecase}}
				</td>
				<td class="text-center nowrap">
					{{contrat.date_fin_contrat | date:'MMM yyyy' | titlecase}}
					<div class="text-sm" *ngIf="contrat.date_fin_contrat > today">(dans {{contrat.date_fin_contrat | timeAgo:true}})</div>
				</td>
				<td class="text-right nowrap">
					<number-display [value]="contrat.cae_mt_a_amortir" type="currency"></number-display>
				</td>
				<td class="text-right nowrap">
					<number-display [value]="contrat.objectif" type="currency"></number-display>
				</td>
				<td class="text-right nowrap">
					<number-display [value]="contrat.taux_amortissement" type="percent"></number-display>
				</td>
				<td class="text-right nowrap">
					<number-display [value]="contrat.mt_reste_a_faire" type="currency"></number-display>
				</td>
				<td class="text-center nowrap">
					<ng-container *ngIf="contrat.pourcentageDureeRestante >= 75">
						{{contrat.cae_type_fin_contrat |titlecase}} de <number-display [value]="contrat.mt_fin_contrat" type="currency"></number-display>
					</ng-container>
				</td>

				<td class="text-center nowrap hide-print">
					<button
						type="button"
						pButton
						[icon]="canEdit? 'pi pi-pencil' : 'pi pi-eye'"
						class="p-button-xs p-button-rounded p-button-text"
						(click)="showContratAmortissementExpositionDialog(contrat)"
					></button>
					<button
						type="button"
						pButton
						icon="pi pi-trash"
						class="p-button-xs p-button-rounded p-button-text p-button-danger"
						(click)="deleteContratAmortissementExposition(contrat)"
						*ngIf="canEdit"
					></button>
				</td>
			</tr>
		</ng-template>

	</p-table>

</div>


